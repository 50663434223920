
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                return _createElement('div', { 'className': 'cm_related_item productCarousel-slide slick-slide' }, _createElement('article', { 'className': 'card' }, _createElement('figure', { 'className': 'card-figure' }, this.on_sale ? _createElement('div', {
                    'className': 'sale-flag-side',
                    'key': '128'
                }, _createElement('span', { 'className': 'sale-text' }, 'Sale')) : null, _createElement('a', { 'href': this.custom_url }, _createElement('div', { 'className': 'card-img-container' }, _createElement('img', {
                    'src': this.imageOrDefault(this.images_image_thumbnail1),
                    'alt': window.Convermax.removeSpecialCharacters(this.removeHTML(this.name)),
                    'title': window.Convermax.removeSpecialCharacters(this.removeHTML(this.name)),
                    'className': 'card-image lazyautosizes ls-is-cached lazyloaded',
                    'onError': this.onImageError
                })))), _createElement('div', { 'className': 'card-body' }, this.brand_name ? _createElement('a', {
                    'className': 'card_brand-link',
                    'href': this.brand_url,
                    'key': '703'
                }, _createElement('p', mergeProps({ 'className': 'card-text' }, { dangerouslySetInnerHTML: { __html: this.brand_name } }))) : null, _createElement('h4', { 'className': 'card-title' }, _createElement('a', { 'href': this.custom_url }, this.name, ' | ', this.sku)), _createElement('div', { 'className': 'card-text' }, this.on_sale ? _createElement('div', {
                    'className': 'price-section price-section--withoutTax non-sale-price--withoutTax',
                    'key': '1031'
                }, '\n          Was:\n          ', _createElement('span', {
                    'data-product-non-sale-price-without-tax': true,
                    'className': 'price price--non-sale'
                }, '\n            ', this.formatPrice(this.regular_price), '\n          ')) : null, _createElement('div', { 'className': 'price-section price-section--withoutTax' }, this.on_sale ? _createElement('span', {
                    'className': 'price-now-label',
                    'key': '1394'
                }, 'Now: ') : null, _createElement('span', { 'className': 'price price--withoutTax' }, this.formatPrice(this.price)))), _createElement('figcaption', { 'className': 'card-figcaption-euro desktop' }, _createElement('div', { 'className': 'card-figcaption-body' }, _createElement('a', {
                    'href': '/cart.php?action=add&product_id=' + this.id,
                    'className': 'button button--small card-figcaption-button'
                }, _createElement('i', { 'className': 'icon' }, _createElement('svg', {}, _createElement('use', { 'xlinkHref': '#icon-cart' }))), '\n            Add to Cart\n          '))))));
            }, { count: undefined })];
    }
    return _createElement('section', { 'className': 'cm_RelatedItems productCarousel slick-initialized slick-slider' }, _createElement('div', { 'className': 'slick-list draggable' }, _createElement.apply(this, [
        'div',
        { 'className': 'slick-track cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ])));
}
        export const componentNames = []