//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-460:_5782,_1344,_5212,_872,_484,_2504,_2584,_8112;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-460')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-460', "_5782,_1344,_5212,_872,_484,_2504,_2584,_8112");
        }
      }catch (ex) {
        console.error(ex);
      }