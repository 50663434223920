//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-460:_3632,_2788,_3596,_8722,_968,_3284,_9956,_4200;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-460')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-460', "_3632,_2788,_3596,_8722,_968,_3284,_9956,_4200");
        }
      }catch (ex) {
        console.error(ex);
      }