
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function fitmentTableTabRT () {
    return this.vehicleSpecific || this.manyFitments ? _createElement('div', {
        'className': 'cm_fitment-table__tab',
        'key': '0'
    }, _createElement('span', { 'className': 'tab-title' }, 'Fitments'), _createElement('svg', { 'className': 'accordion-icon arrow down' }, _createElement('use', { 'xlinkHref': '#icon-v-down' }))) : null;
}
        export const componentNames = []