export default {
  BUTTON_PRIMARY_CLASS: 'button button--primary',
  BUTTON_SECONDARY_CLASS: 'button button--primary',
  LOAD_MORE_BUTTON_CLASS: 'button button--primary',

  MAX_PRICE: 'Max',
  MIN_PRICE: 'Min',

  MAIN_CONTENT_CLASS: 'page-content',
  FACET_PANEL_CLASS: 'page-sidebar',

  GARAGE_ICON_CONTAINER_CLASS: `{this.size ? '' : 'garage-full'}`,
  GARAGE_SIZE: '<span class="countPill countPill--positive" key="garage-size">{{size}}</span>',

  VEHICLE_WIDGET_COLLAPSIBLE_CLASS: 'container',
  CHANGE_VEHICLE: 'Change',
  DISCARD_VEHICLE: 'Clear',

  SEARCH_BOX_INPUT_CLASS: 'form-input',
  SEARCH_BOX_BUTTON_CLASS: 'search-icon submit search-submit btn-search',
};
