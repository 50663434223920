import euroSportTuningDefault from '../eurosport-tuning/config.js';
import { bigcommerceAppClientId } from '../_common/constants.js';

globalThis.Convermax.isLoggedIn = window.document.querySelector(
  '.navUser .navBar-action[href="/login.php?action=logout"]',
);

const customerGroupId = getGroupId();
async function getGroupId() {
  if (!window.Convermax?.isLoggedIn) {
    return '';
  }
  const groupId = await new Promise((resolve) => {
    const xmlHttp = new XMLHttpRequest();
    xmlHttp.onreadystatechange = function () {
      if (xmlHttp.readyState === 4) {
        if (xmlHttp.status === 200) {
          const jwt = xmlHttp.responseText;
          const [, jsonPart] = jwt.split('.');
          const { customer } = JSON.parse(atob(jsonPart));
          resolve(customer.group_id);
        } else if (xmlHttp.status === 404) {
          resolve(null);
        } else {
          resolve(null);
        }
      }
    };
    xmlHttp.open('GET', `/customer/current.jwt?app_client_id=${bigcommerceAppClientId}`, true);
    xmlHttp.send();
  });

  return groupId || '';
}

function getActiveCurrency() {
  return document.querySelector(`[data-dropdown=currencySelection]`)?.textContent.trim().toLowerCase();
}

function formatPrice(price) {
  if (!price) {
    return '';
  }

  return new Intl.NumberFormat('en-CA', {
    style: 'currency',
    currency: getActiveCurrency(),
  }).format(price);
}

export default {
  includes: ['eurosport-tuning'],
  ...euroSportTuningDefault,
  SearchRequestDefaults: {
    ...euroSportTuningDefault.SearchRequestDefaults,
    extra: { ...euroSportTuningDefault.SearchRequestDefaults.extra, customerGroupId },
  },
  product: {
    ...euroSportTuningDefault.product,
    formatPrice,
  },
  autocomplete: {
    requestDefaults: {
      extra: { ...euroSportTuningDefault.autocomplete.requestDefaults.extra, customerGroupId },
    },
  },
};
