import { SearchPage } from 'Components'
import TemplateSearchPage from 'Stores/_default-store/Templates/SearchPage.rt'
import TemplateMainContent from 'Stores/eurosport-tuning/Templates/MainContent.rt'
import { FacetPanel } from 'Components'
import TemplateFacetPanelContainer from 'Stores/_default-store/Templates/FacetPanelContainer.rt'
import { SearchHeader } from 'Components'
import { SearchResult } from 'Components'
import { Garage } from 'Components'
import TemplatefitmentSearchgarage from 'Stores/_default-store/Templates/fitmentSearch/garage.rt'
import { SearchBox } from 'Components'
import TemplateSearchBox from 'Stores/_default-store/Templates/SearchBox.rt'
import { SearchBoxDialogButton } from 'Components'
import TemplatesearchBoxdialogButtonMobile from 'Stores/_default-store/Templates/searchBox/dialogButtonMobile.rt'
import { VehicleWidget } from 'Components'
import TemplatefitmentSearchcollapsibleVehicleWidget from 'Stores/_default-store/Templates/fitmentSearch/collapsibleVehicleWidget.rt'
import { FacetBar } from 'Components'
import { FacetTiles } from 'Components'
import TemplatefacetTileshierarchicalContainer from 'Stores/_default-store/Templates/facetTiles/hierarchicalContainer.rt'
import TemplatefitmentSearchcategoryListVehicleLabel from 'Stores/eurosport-tuning/Templates/fitmentSearch/categoryListVehicleLabel.rt'
import TemplatefacetTilesmanufacturersContainer from 'Stores/eurosport-tuning/Templates/facetTiles/manufacturersContainer.rt'
import { RelatedItems } from 'Components'
import TemplateproductrelatedItems from 'Stores/eurosport-tuning/Templates/product/relatedItems.rt'
import TemplatefitmentSearchverifyFitment from 'Stores/_default-store/Templates/fitmentSearch/verifyFitment.rt'
import { FitmentTable } from 'Components'
import TemplatefitmentSearchfitmentTableTab from 'Stores/eurosport-tuning/Templates/fitmentSearch/fitmentTableTab.rt'
import TemplatefitmentSearchfitmentTable from 'Stores/_default-store/Templates/fitmentSearch/fitmentTable.rt'
import { FacetDialog } from 'Components'
import TemplateFacetDialog from 'Stores/_default-store/Templates/FacetDialog.rt'
import { SearchBoxDialog } from 'Components'
import TemplateSearchBoxDialog from 'Stores/_default-store/Templates/SearchBoxDialog.rt'
import { ContextDialog } from 'Components'
import TemplatefitmentSearchdialog from 'Stores/_default-store/Templates/fitmentSearch/dialog.rt'

export const compMap = {
  SearchPage,
FacetPanel,
SearchHeader,
SearchResult,
Garage,
SearchBox,
SearchBoxDialogButton,
VehicleWidget,
FacetBar,
FacetTiles,
RelatedItems,
FitmentTable,
FacetDialog,
SearchBoxDialog,
ContextDialog
} 
export const tplMap = {
  'SearchPage': TemplateSearchPage,
'MainContent': TemplateMainContent,
'FacetPanelContainer': TemplateFacetPanelContainer,
'fitmentSearch/garage': TemplatefitmentSearchgarage,
'SearchBox': TemplateSearchBox,
'searchBox/dialogButtonMobile': TemplatesearchBoxdialogButtonMobile,
'fitmentSearch/collapsibleVehicleWidget': TemplatefitmentSearchcollapsibleVehicleWidget,
'facetTiles/hierarchicalContainer': TemplatefacetTileshierarchicalContainer,
'fitmentSearch/categoryListVehicleLabel': TemplatefitmentSearchcategoryListVehicleLabel,
'facetTiles/manufacturersContainer': TemplatefacetTilesmanufacturersContainer,
'product/relatedItems': TemplateproductrelatedItems,
'fitmentSearch/verifyFitment': TemplatefitmentSearchverifyFitment,
'fitmentSearch/fitmentTableTab': TemplatefitmentSearchfitmentTableTab,
'fitmentSearch/fitmentTable': TemplatefitmentSearchfitmentTable,
'FacetDialog': TemplateFacetDialog,
'SearchBoxDialog': TemplateSearchBoxDialog,
'fitmentSearch/dialog': TemplatefitmentSearchdialog
};