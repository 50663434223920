
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function categoryListVehicleLabelRT () {
    return _createElement('span', {}, this.isVehicleSelected ? [
        'Search Results For: ',
        this.vehicleString
    ] : null, !this.isVehicleSelected ? ['Category List'] : null);
}
        export const componentNames = []