
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function manufacturersContainerRT () {
    function repeatHierarchicalGroups1(hierarchicalGroups, hierarchicalGroupsIndex) {
        return [hierarchicalGroups(function () {
                return _createElement('div', { 'className': 'cm_vehicle-categories__topline-wrapper' }, _createElement('a', {
                    'href': !this.subvalues.length ? `${ this.href }` : `#nav_${ this.term }`,
                    'className': 'cm_vehicle-categories__topline-link',
                    'title': this.value
                }, _createElement('img', {
                    'alt': this.value,
                    'className': 'cm_vehicle-categories__topline-image cm_vehicle-categories_category-image',
                    'src': this.getImageOrDefault(this.imageUrl)
                }), _createElement('div', { 'className': 'cm_vehicle-categories__topline-title' }, this.value)));
            }, { count: undefined })];
    }
    function repeatHierarchicalGroups2(hierarchicalGroups, hierarchicalGroupsIndex) {
        return [hierarchicalGroups(function () {
                function repeatSubvalues1(subvalues, subvaluesIndex) {
                    return [subvalues(function () {
                            return _createElement('div', { 'className': 'cm_vehicle-categories_category-container' }, _createElement('a', {
                                'href': this.href,
                                'className': 'cm_vehicle-categories_link'
                            }, _createElement('div', { 'className': 'cm_vehicle-categories_category-image-container' }, this.imageUrl ? _createElement('img', {
                                'alt': window.Convermax.removeSpecialCharacters(this.value),
                                'title': window.Convermax.removeSpecialCharacters(this.value),
                                'className': 'cm_vehicle-categories_category-image',
                                'src': this.getImageOrDefault(this.imageUrl),
                                'key': '186'
                            }) : null, !this.imageUrl ? _createElement('h3', { 'key': '436' }, this.value) : null), _createElement('div', { 'className': 'cm_vehicle-categories_category-title-container' }, _createElement('div', { 'className': 'cm_vehicle-categories_category-title' }, this.value), _createElement('svg', {
                                'className': 'icon chevron',
                                'title': 'icon chevron right'
                            }, _createElement('path', { 'd': 'M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z' })))));
                        }, { count: undefined })];
                }
                return _createElement('div', { 'className': 'cm_vehicle-categories_category-wrapper' }, !this.subvalues.length && !this.isMultiLevel ? [_createElement('div', {
                        'className': 'cm_vehicle-categories_category-container',
                        'key': '560'
                    }, _createElement('a', {
                        'href': this.href,
                        'className': 'cm_vehicle-categories_link'
                    }, _createElement('div', { 'className': 'cm_vehicle-categories_category-image-container' }, this.imageUrl ? _createElement('img', {
                        'alt': window.Convermax.removeSpecialCharacters(this.value),
                        'title': window.Convermax.removeSpecialCharacters(this.value),
                        'className': 'cm_vehicle-categories_category-image',
                        'src': this.getImageOrDefault(this.imageUrl),
                        'key': '315'
                    }) : null, !this.imageUrl ? _createElement('h3', { 'key': '565' }, this.value) : null), _createElement('div', { 'className': 'cm_vehicle-categories_category-title-container' }, _createElement('div', { 'className': 'cm_vehicle-categories_category-title' }, this.value), _createElement('svg', {
                        'className': 'icon chevron',
                        'title': 'icon chevron right'
                    }, _createElement('path', { 'd': 'M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z' })))))] : null, this.subvalues.length ? [
                    _createElement('h1', {
                        'className': 'cm_vehicle-categories_category-wrapper-title',
                        'id': 'nav_' + this.term,
                        'key': '9421'
                    }, _createElement('a', {
                        'className': 'cm_vehicle-categories_category-wrapper-title-link cm_vehicle-categories_link',
                        'href': this.href
                    }, '\n        ', this.value, '\n      ')),
                    [this.vehicleWidget(function () {
                            return _createElement('div', { 'className': 'cm_vehicle-widget__vehicle-category-wrapper cm_vehicleWidget' }, this.template === 'locked' ? _createElement('div', {
                                'className': 'cmTemplate_locked',
                                'key': '81'
                            }, this.vehicleString) : null);
                        }, {
                            widgetName: 'undefined',
                            items: undefined
                        })],
                    _createElement.apply(this, [
                        'div',
                        {
                            'className': 'cm_vehicle-categories_category-repeater  cmRepeater_subvalues',
                            'key': '9425'
                        },
                        _map(this.subvalues, repeatSubvalues1.bind(this))
                    ])
                ] : null);
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_vehicle-categories' + (!this.isMultiLevel ? ' cm_vehicle-categories__flat ' : ' ') }, this.isMultiLevel ? _createElement.apply(this, [
        'div',
        {
            'className': 'cm_vehicle-categories_category-wrapper-repeater cm_vehicle-categories__topline cmRepeater_hierarchicalGroups',
            'key': '118'
        },
        _map(this.hierarchicalGroups, repeatHierarchicalGroups1.bind(this))
    ]) : null, _createElement.apply(this, [
        'div',
        { 'className': 'cm_vehicle-categories_category-wrapper-repeater  cmRepeater_hierarchicalGroups' },
        _map(this.hierarchicalGroups, repeatHierarchicalGroups2.bind(this))
    ]));
}
        export const componentNames = ["cm:vehicleWidget"]